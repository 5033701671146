.ch_container {
  width: 270px;
  height: 390px;
}
.ch_p1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* body text 2 */
  margin: 0;
  color: #2a363a;
  width: 180px;
}
.ch_p2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  /* body text 1 */

  color: #4e5a5f;
  width: 180px;
  margin: 0;
}
.ch-row1 {
  padding: 1rem 1rem;
  border-top: 1px solid #e1e1e1;
  cursor: pointer;
}
.ch_title {
  display: flex;
  margin: 0;
  background-color: #ff5c6a;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  width: 270px;
  height: 50px;
  align-items: center;
  padding-left: 1rem;
  /* identical to box height */

  color: #ffffff;
}
