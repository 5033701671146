.header-container {
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: end;
  border-bottom: 1px solid #e1e1e1;
  background-color: #fafafa;;
  padding: 0 2rem;
}
.header-img {
  margin: 0 1rem;
}
.header-p1 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  margin: 0 1rem;
  color: #2c405a;
  cursor: pointer;
}
.header-div1 {
  position: relative;
  cursor: pointer;
}
.header-img1{
  position: absolute;
  top: 14px;
  left: 30px;
}
.header-span1{
  position: absolute;
  top: -8px;
  left: 33px;
  font-size: 11px;
  color: white;
}
.ant-popover-inner-content{
  padding: 0;
}
.flag-img {
  background: #ff5c6a;
  width: 18px;
  height: 12px;
  vertical-align: middle;
  margin-right: 4px;
}
