.logo-menu {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #000000;
  margin: 0 1rem;
}
.ant-menu > .ant-menu-item:hover {
  color: #ff5c6a !important;
}
.ant-menu > .ant-menu-submenu:hover {
  color: #ff5c6a !important;
}
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  color: #ff5c6a !important; 
}
