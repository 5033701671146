.fleets-p1 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;

  /* text1 */
  margin-bottom: 0.2rem;
  margin-top: 0.1rem;
  color: #2c405a;
}
.fleets-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  /* text1 */

  color: #2c405a;

}
.fleets-p2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  margin-bottom: 0.5px;
  color: #2c405a;
}
.config {
  display: flex;
  align-items: center;
  gap : 2.5rem;
  margin-bottom: 1rem;

}
.config span{
  margin-right: 1rem;
}
.fleet-vehicle_table {
  margin-top: 2rem;
}