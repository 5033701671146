@import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: 'Work Sans', sans-serif !important;
  font-style: normal;
}
.mapboxgl-ctrl-attrib {
  display: none;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.ant-modal-header {
  background-color: #2c405a;
}
.ant-modal-title {
  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;

  color: #ffffff;
}
.ant-modal-close-x {
  color: white;
}
.ant-modal-footer{
  display: none;
}
.ant-modal-body{
  padding: 0;
}
.fa-info-circle{
  font-size: 12px;
}


.hidden {
  display: none!important;
}
.flex-1 {
  flex: 1;
}