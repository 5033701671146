.role_div1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.role_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  color: #2c405a;
}
