.cardRiders_p1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  /* text1 */

  color: #2c405a;
  margin: 5px 0;
}
.cardRiders_p2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #647278;
}
