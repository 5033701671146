.map_p1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #334045;
}
.map_title{
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #2c405a;
}

#advance-filter-block {
  width: 275px;
}
#advance-filter-block {
  overflow-x: auto;
}
#advance-filter-back {
  margin: 5px 0px 0px;
  float: left;
  height: 20px;
  padding: 5px 12px;
  min-height: 25px;
  line-height: 14px;
}
.animated {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-duration: 1s;
  animation-fill-mode: backwards;
}
.animated {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
.small-box-footer{
  border-radius: 50px;
  color: #758d96;
  display: inline-block;
  font-size: 28px;
  line-height: 10px;
  text-align: center;
}

.circle-icon {
  background: #ffc0c0;
  padding:15px;
  border-radius: 50%;
}
.arrowIcon {
  margin-left: 5px;
  margin-right: 30% ;
  font-size: 23px !important;
  border-radius: 50%;
  transition: 0.4s;
}
.arrowIcon:hover {
  color: white;
  background-color: #ff5c6a;
}
.itemBattery {
  min-width: 70px;
}
.line {
  margin-top: 20px;
  width: 50%;
  border-top: 2px solid;
  margin-left: 25%;
  margin-right: 25%;
  border-color: #bbc6c9;
}