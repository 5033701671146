.org_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  color: #2c405a;
}
.org_div1 {
  padding: 1rem;
  background-color: #fff;
  border-radius: 5px;
}
.org_p1 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  /* text1 */

  color: #2c405a;
}
.org_address {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* text1 */

  color: #2c405a;
}
.org_div2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}