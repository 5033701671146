.feedbackOption-p-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  /* text1 */

  color: #2c405a;
}
.react-colorful__saturation-pointer {
  width: 9px !important;
  height: 9px !important;
}
.react-colorful__hue-pointer {
  width: 24px !important;
  height: 24px !important;
}
