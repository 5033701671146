.cardGeneralFleet_p {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  /* text1 */

  color: #2c405a;
}
.cardGeneralFleet_p1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #647278;
}
