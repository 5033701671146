#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: #fff;
}

.site-layout .site-layout-background {
  background: #fff;
}
.app-p1{
  display: flex;
  align-items: center;
  text-align: center;
  padding: 1rem 1.5rem;
  justify-content: space-between;
}
.fa-bars{
  font-size: 28px;
  padding-top: 1rem;
}
.app-sider{
  box-shadow: rgba(110, 106, 106, 0.15) 2.4px 2.4px 3.2px;
}