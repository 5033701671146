.addMultipleVehicles_p1 {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;

  /* text1 */
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.3rem;

  color: #2c405a;
}
