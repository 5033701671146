.va_p_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  color: #2c405a;
}
.va_p1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  margin-bottom: 0.5px;
  color: #2c405a;
}
.ant-switch-checked{
  background-color: #24BB69;
}